<template>
  <v-card>
    <download-excel
      :fetch="getExport"
      :fields="json_fields"
      worksheet="Transakcije"
      type="xls"
      v-bind:name="getName"
    >
      <v-btn depressed color="teal white--text">
        Izvoz podatkov
      </v-btn>
    </download-excel>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Išči"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Zapisov na strani' }"
      ref="myTable"
    >
      <!-- eslint-disable -->
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="
            item.active == '1' ? 'green' : item.active == '0' ? 'red' : 'grey'
          "
          dark
        >
          {{
            item.active == "1"
              ? "Aktivna"
              : item.active == "0"
              ? "Neaktivna"
              : "grey"
          }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          elevation="4"
          :disabled="!item.last"
          fab
          class="teal"
          small
          @click="blockTrans(item)"
        >
          <v-icon>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </template>
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} od {{ props.itemsLength }}
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <v-btn
      color="teal white--text"
      dark
      @click="scan"
      fixed
      fab
      x-large
      bottom
      right
    >
      <v-icon>
        {{ icons.mdiBarcodeScan }}
      </v-icon>
    </v-btn>
    <confirm-dialog
      :dialog="dialog"
      :dialogTitle="dialogTitle"
      :dialogText="dialogText"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    ></confirm-dialog>
  </v-card>
</template>
<script>
// import VueSocketIOExt from 'vue-socket.io-extended';
// import Vue from 'vue';
// import io from 'socket.io-client';

// const socket = io('http://localhost:3000');
// Vue.use(VueSocketIOExt, socket);
import axios from "axios";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import router from "../router";
import {
  mdiDelete,
  mdiPlusThick,
  mdiMinusThick,
  mdiCreditCard,
  mdiBarcodeScan,
  mdiCheck,
  mdiPrinter,
} from "@mdi/js";
export default {
  components: {
    ConfirmDialog,
  },
  computed: {
    getName() {
      let date = new Date();
      let fname =
        "TRANSAKCIJE_" +
        date.getFullYear() +
        ("0" + date.getMonth()).slice(-2) +
        ("0" + date.getDate()).slice(-2) +
        ("0" + date.getHours()).slice(-2) +
        ("0" + date.getMinutes()).slice(-2) +
        ("0" + date.getSeconds()).slice(-2);
      return fname;
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    axios
      .get("/api/transactions", {
        headers: { Authorization: "Bearer " + user.token },
      })
      .then(
        function(response) {
          console.log(response);
          this.transactions = response.data;
        }.bind(this),
        (error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            router.push("/login");
          }
        }
      );
  },
  methods: {
    scan() {
      this.$router.push({
        name: "scan",
      });
    },
    getExport() {
      // `this` points to the vm instance
      if (this.search.length !== 0) {
        this.storno();
        return this.results;
      } else {
        return this.transactions;
      }
    },
    storno() {
      for (var i = 0; i < this.transactions.length; i++) {
        for (let row in this.transactions[i]) {
          if (
            this.transactions[i][row]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) !== -1
          ) {
            this.results.push(this.transactions[i]);
            break;
            // j = this.transactions[i].length + 1;
          }

          if (
            row == "Card" &&
            this.transactions[i][row].code
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) !== -1
          ) {
            this.results.push(this.transactions[i]);
            break;
            // j = this.transactions[i].length + 1;
          }
        }
      }
    },
    async blockTrans(item) {
      const res = await this.$confirm("Želiš stornirati transakcijo?", {
        title: "Storno transakcije",
        buttonTrueText: "Potrdi",
        buttonFalseText: "Zavrzi",
      });
      if (res) {
        let user = JSON.parse(localStorage.getItem("user"));

        axios
          .post(
            "/api/last_transaction",
            {
              transID: item.id,
              active: false,
            },
            { headers: { Authorization: "Bearer " + user.token } }
          )
          .then(
            (response) => {
              console.log(response);
              axios
                .get("/api/transactions", {
                  headers: { Authorization: "Bearer " + user.token },
                })
                .then(
                  function(response) {
                    console.log(response);
                    this.transactions = response.data;
                  }.bind(this)
                );
              // for (let i = 0; i < this.transactions.length; i++) {
              //   if (this.transactions[i].id == item.id) {
              //     this.transactions[i].active = false;
              //   }
              // }
              // for (let i = 0; i < this.results.length; i++) {
              //   if (this.results[i].id == item.id) {
              //     this.results[i].active = false;
              //   }
              // }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    onConfirm() {
      // Store.dispatch(
      //   "products/deleteProduct",
      //   this.productId,
      // ).then(() => {
      //   Store.dispatch("products/searchProducts", this.query, this.pagination);
      //   Store.dispatch("products/closeSnackBar", 2000);
      // });
      this.dialog = false;
    },
    onCancel() {
      // this.productId = "";
      this.dialog = false;
    },
  },
  data() {
    return {
      search: "",
      xslName: "test.xls",
      transactions: [],
      results: [],
      json_fields: {
        "Id Kartice": "CardId",
        "Črtna koda": "Card.code",
        "Stanje po spremembi": "newBalance",
        Sprememba: "diffBalance",
        Status: {
          field: "active",
          callback: (value) => {
            if (value) {
              return "Aktivna";
            } else {
              return "Neaktivna";
            }
          },
        },
        Kreirano: "createdAt",
        Posodobljeno: "updatedAt",
        "Nazadnje urejal": "user",
      },
      headers: [
        { text: "Id Kartice", value: "CardId" },
        { text: "Črtna koda", value: "Card.code" },
        { text: "Stanje po spremembi", value: "newBalance" },
        { text: "Sprememba", value: "diffBalance" },
        { text: "Status", value: "active" },
        { text: "Kreirano", value: "createdAt" },
        { text: "Posodobljeno", value: "updatedAt" },
        { text: "Nazadnje urejal", value: "user" },
        { text: "", value: "actions" },
      ],
      icons: {
        mdiDelete,
        mdiPlusThick,
        mdiMinusThick,
        mdiCreditCard,
        mdiBarcodeScan,
        mdiCheck,
        mdiPrinter,
      },
      dialog: false,
      dialogTitle: "Product Delete Dialog",
      dialogText: "Do you want to delete this product?",
      makeStorno: true,
    };
  },
};
</script>

function newFunction() { return this; }
