// import config from 'config';
import { authHeader } from '../_helpers';
// import Vue from 'vue';
export const userService = {
    login,
    logout,
    getAll
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`/api/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                // this.$store.state.loggedIn = true;
            }
            return user;
        });
   
// Vue.axios.post('/users/authenticate', { username, password })
//         .then(function (response) {
//             handleResponse(response);
//         })
//             .then(user => {
//              // login successful if there's a jwt token in the response
//              if (user.token) {
//                  // store user details and jwt token in local storage to keep user logged in between page refreshes
//                  localStorage.setItem('user', JSON.stringify(user));
//              }

//              return user;
//          });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/api/users`, requestOptions).then(handleResponse);
}
// function getById(id) {
//     return fetch(`/users/${id}`, requestOptions)
//         .then(handleResponse);
// }
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
           
          
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                
                logout();
                location.reload(true);
            }
            
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}