<template>
  <!-- <div v-if="false" class="hello"> -->
      <div>
     
    <StreamBarcodeReader
      @decode="(a, b, c) => onDecode(a, b, c)"
      @loaded="() => onLoaded()"
    ></StreamBarcodeReader>
    Input Value: {{ text || "Nothing" }}
      
  </div>
</template>

<script >

import {StreamBarcodeReader} from "vue-barcode-reader";
// import Vue from 'vue';
import axios from 'axios';

// import VueAxios from 'vue-axios';
export default {  
   components: {
        StreamBarcodeReader      
    },
  data() {
    return {
      text: "",
      id: null,
      card_status: false
    };
  },
  methods: {
  onDecode(a, b, c) {
      console.log(a, b, c);
      this.text = a;
       let user = JSON.parse(localStorage.getItem('user'));
        console.log("heja1");
        console.log(user.token);
        axios.get('http://localhost:3000', { 'headers': {'Authorization': 'Bearer ' + user.token }})
            .then(function( response ){
                console.log(response);
            }.bind(this));
  console.log("heja2");
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === a) {
          console.log("heja");
          
          this.text = "";
        }
      }, 5000);
    },
    onLoaded() {
     
    },
    scan(event){
      
      console.log(event);
    }
  }
}
</script>
<style>
.v-application {
  text-align: center;
}
</style>
