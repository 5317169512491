<template>
  <v-card>
    <div>
      <download-excel
        class="btn btn-default text-right"
        :fetch="getExport"
        :fields="json_fields"
        worksheet="Kartice"
        type="xls"
        v-bind:name="getName"
      >
        <v-btn depressed color="teal white--text">
          Izvoz podatkov
        </v-btn>
      </download-excel>

      <v-btn color="teal white--text" fab small @click="dialog = true" v-blur>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Išči"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="cards"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Zapisov na strani' }"
    >
      <!-- eslint-disable -->
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="
            item.active == '1' ? 'green' : item.active == '0' ? 'red' : 'grey'
          "
          dark
        >
          {{
            item.active == "1"
              ? "Aktivna"
              : item.active == "0"
              ? "Neaktivna"
              : "grey"
          }}
        </v-chip>
      </template>
      <template v-slot:item.value="{ item }">
        {{ item.value | formatQuantity }}
      </template>
      <template v-slot:item.blockedAt="{ item }">
        {{ item.blockedAt | formatBlockDate }}
      </template>
      <template v-slot:item.issuedAt="{ item }">
        {{ item.issuedAt | formatDate }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          elevation="4"
          fab
          class="teal mr-2"
          small
          @click="deRegisterCard(item)"
        >
          <v-icon>
            mdi-checkbox-marked
          </v-icon>
          <!-- Izdaja -->
        </v-btn>

        <v-btn elevation="4" fab class="teal" small @click="blockCard(item)">
          <v-icon>
            mdi-block-helper
          </v-icon>
        </v-btn>
      </template>
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} od {{ props.itemsLength }}
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <v-btn
      color="teal white--text"
      dark
      @click="scan"
      fixed
      fab
      x-large
      bottom
      right
    >
      <v-icon>
        {{ icons.mdiBarcodeScan }}
      </v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Čakam vnos kode...</span>
          <v-progress-linear
            color="green accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Vnesi BAR kodo*"
                  v-model="bCode"
                  required
                  solo
                  ref="searchFocus"
                  @input="charCount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*prosim preberite črtno kodo</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Zapri
          </v-btn>
          <v-btn color="blue darken-1" text v-on:click="regCard">
            Potrdi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="warning" :timeout="timeout">
      {{ emWarning }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="warning = false">
          Zapri
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
// import VueSocketIOExt from 'vue-socket.io-extended';
// import Vue from 'vue';
// import io from 'socket.io-client';

// const socket = io('http://localhost:3000');
// Vue.use(VueSocketIOExt, socket);
import axios from "axios";
import router from "../router";
import validbarcode from "barcode-validator";
import {
  mdiDelete,
  mdiPlusThick,
  mdiMinusThick,
  mdiCreditCard,
  mdiBarcodeScan,
  mdiCheck,
  mdiPrinter,
} from "@mdi/js";
export default {
  computed: {
    getName() {
      let date = new Date();
      let fname =
        "KARTICE" +
        date.getFullYear() +
        ("0" + date.getMonth()).slice(-2) +
        ("0" + date.getDate()).slice(-2) +
        ("0" + date.getHours()).slice(-2) +
        ("0" + date.getMinutes()).slice(-2) +
        ("0" + date.getSeconds()).slice(-2);
      return fname;
    },
  },
  methods: {
    scan() {
      this.$router.push({
        name: "scan",
      });
    },
    charCount() {
      if (this.bCode.length == 13) {
        this.regCard();
      }
    },
    getExport() {
      // `this` points to the vm instance
      if (this.search.length !== 0) {
        this.storno();
        return this.results;
      } else {
        return this.cards;
      }
    },
    storno() {
      for (var i = 0; i < this.cards.length; i++) {
        for (let row in this.cards[i]) {
          if (
            this.cards[i][row]
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) !== -1
          ) {
            this.results.push(this.cards[i]);
            break;
            // j = this.transactions[i].length + 1;
          }

          if (
            row == "Card" &&
            this.cards[i][row].code
              .toString()
              .toLowerCase()
              .indexOf(this.search.toLocaleLowerCase()) !== -1
          ) {
            this.results.push(this.cards[i]);
            break;
            // j = this.transactions[i].length + 1;
          }
        }
      }
    },
    async blockCard(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let status_title;
      if (item.active) {
        status_title = "Blokiraj kartico";
      } else {
        status_title = "Deblokiraj kartico";
      }
      const res = await this.$confirm(status_title, {
        title: "Blokada kartice!",
        buttonTrueText: "Potrdi",
        buttonFalseText: "Zavrzi",
        color: "green",
      });
      if (res) {
        axios
          .post(
            "/api/card_status",
            {
              card_id: item.id,
              stat: !item.active,
            },
            { headers: { Authorization: "Bearer " + user.token } }
          )
          .then(
            (response) => {
              for (let i = 0; i < this.cards.length; i++) {
                if (this.cards[i].id == item.id) {
                  this.cards[i].active = response.data.active;
                  this.cards[i].blockedAt = response.data.blockedAt;
                  this.cards[i].user = user.username;
                  // this.cards[i].issuedAt = response.data.issuedAt;
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    async deRegisterCard(item) {
      let user = JSON.parse(localStorage.getItem("user"));
      let status_title;
      let status;
      if (item.issuedAt != "Invalid date") {
        status_title = "Deregistracija kartice!";
        status = true;
      } else {
        status_title = "Registracija kartice";
        status = false;
      }
      const res = await this.$confirm(status_title, {
        title: "Registracija kartice",
        buttonTrueText: "Potrdi",
        buttonFalseText: "Zavrzi",
        color: "green",
      });
      if (res) {
        axios
          .post(
            "/api/card_status_reg",
            {
              card_id: item.id,
              status: status,
            },
            { headers: { Authorization: "Bearer " + user.token } }
          )
          .then(
            (response) => {
              for (let i = 0; i < this.cards.length; i++) {
                if (this.cards[i].id == item.id) {
                  // this.cards[i].active = response.data.active;
                  this.cards[i].issuedAt = response.data.issuedAt;
                  this.cards[i].user = user.username;
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
    regCard() {
      if (!validbarcode(this.bCode) || this.bCode.length != 13 || !( this.bCode >= 1888037000000 && this.bCode <= 1888037999999)) {
        this.warning = true;
        this.emWarning = "Koda ni formata EAN13";
        this.bCode = null;
        this.dialog = false;
        return;
      }
      let user = JSON.parse(localStorage.getItem("user"));
      this.dialog = false;
      let that = this;
      axios
        .post(
          "/api/card_register",
          {
            code: this.bCode,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            console.log(response);
            axios
              .get("/api/cards", {
                headers: { Authorization: "Bearer " + user.token },
              })
              .then(
                function(response) {
                  this.cards = response.data;
                  that.bCode = "";
                }.bind(this),
                (error) => {
                  if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    router.push("/login");
                  }
                }
              );
            // for (let i = 0; i < this.cards.length; i++) {
            //   if (this.cards[i].id == item.id) {
            //     this.cards[i].active = response.data.active;
            //     this.cards[i].blockedAt = response.data.blockedAt;
            //     // this.cards[i].issuedAt = response.data.issuedAt;
            //   }
            // }
          },
          (error) => {
            console.log(error);
            if (
              Object.prototype.hasOwnProperty.call(
                error.response.data,
                "errTxt"
              )
            ) {
              this.warning = true;
              this.emWarning = error.response.data.errTxt;
              that.bCode = "";
            }
          }
        );
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    axios
      .get("/api/cards", {
        headers: { Authorization: "Bearer " + user.token },
      })
      .then(
        function(response) {
          this.cards = response.data;
        }.bind(this)
      );
  },
   watch: {
    dialog(visible) {
      if (visible) {
        this.bCode = "";
        setTimeout(() => {
          this.$refs.searchFocus.focus();
        });
      }
    },
  },
  data() {
    return {
      search: "",
      dialog: false,
      cards: [],
      bCode: "",
      warning: false,
      emWarning: "",
      timeout: 2000,
      results: [],
      json_fields: {
        Id: "id",
        "Črtna koda": "code",
        Stanje: {
          field: "value",
          callback: (value) => {    
             value = value.toString();
              return value.replace(".", ",");       
          },
        },
        Sprememba: "diffBalance",
        Status: {
          field: "active",
          callback: (value) => {
            if (value) {
              return "Aktivna";
            } else {
              return "Neaktivna";
            }
          },
        },
        Kreirano: "createdAt",
        Posodobljeno: "updatedAt",
        Blocked: {
          field: "blockedAt",
          callback: (value) => {
            if (value == "Invalid date") {
              return "";
            } else {
              return value;
            }
          },
        },
        "Nazadnje urejal": "user",
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Črtna koda", value: "code" },
        { text: "Stanje", value: "value" },
        { text: "Kreirano", value: "createdAt" },
        // { text: "Posodobljeno", value: "updatedAt" },
        { text: "Blokirano", value: "blockedAt" },
        { text: "Izdana/prodana", value: "issuedAt" },
        { text: "Nazadnje urejal", value: "user" },
        { text: "Status", value: "active" },
        { text: "", value: "actions" },
      ],
      icons: {
        mdiDelete,
        mdiPlusThick,
        mdiMinusThick,
        mdiCreditCard,
        mdiBarcodeScan,
        mdiCheck,
        mdiPrinter,
      },
    };
  },
  filters: {
    // you can call this whatever you want to

    formatQuantity(value) {
      value = value.toString();
      return value.replace(".", ",");
    },
    formatDate(value) {
      value = value.toString();
      if (value == "Invalid date") {
        value = "Ni registrirana";
      }
      return value;
    },
    formatBlockDate(value) {
      value = value.toString();
      if (value == "Invalid date") {
        value = "";
      }
      return value;
    },
  },
};
</script>
