<template>
  <v-card>
    <div>
      <v-btn color="teal white--text" fab small @click="addUsr = true" v-blur>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Išči"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Zapisov na strani' }"
      ref="myTable"
    >
      <!-- eslint-disable -->
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="
            item.active == '1' ? 'green' : item.active == '0' ? 'red' : 'red'
          "
          dark
        >
          {{
            item.active == "1"
              ? "Aktivna"
              : item.active == "0"
              ? "Blokiran"
              : "Blokiran"
          }}
        </v-chip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          elevation="4"
          fab
          class="teal mr-2"
          small
          @click="changePass(item)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
          <!-- Izdaja -->
        </v-btn>
        <v-btn elevation="4" fab class="teal" small @click="blockUser(item)">
          <v-icon>
            mdi-block-helper
          </v-icon>
        </v-btn>
      </template>
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} od {{ props.itemsLength }}
      </template>
      <!-- eslint-enable -->
    </v-data-table>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Sprememba gesla</span>
        </v-card-title>
        <v-card-text class="mt-10">
          <form @submit.prevent="passReset">
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Novo geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="newPass"
                  label="Novo geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Ponovi geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="confPass"
                  label="Ponovi geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn color="teal white--text" @click="dialog = false">
                Zapri
              </v-btn>
              <v-btn elevation="4" type="submit" color="teal white--text"
                >Ponastavi geslo</v-btn
              >
            </v-card-actions>
            <v-snackbar
              v-if="error"
              :timeout="timeout"
              :top="true"
              :multi-line="mode === 'multi-line'"
              :vertical="mode === 'vertical'"
              v-model="error"
            >
              {{ text }}
              <v-btn class="pink--text" text @click.native="error = false"
                >Close</v-btn
              >
            </v-snackbar>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addUsr" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Dodaj uporabnika</span>
        </v-card-title>
        <v-card-text class="mt-10">
          <form @submit.prevent="addUser">
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Uporabniško ime</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  v-model="userName"
                  label="Uporabniško ime"
                  :rules="[() => !!userName || 'Vnos je obvezen']"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Naziv 1</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  v-model="name1"
                  label="Naziv 1"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Naziv 2</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  v-model="name2"
                  label="Naziv 2"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Skupina</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-select
                  v-model="authorization"
                  :items="['User', 'Api']"
                  label="Skupina"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="newPass"
                  label="Geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Ponovi geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="confPass"
                  label="Ponovi geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn color="teal white--text" @click="addUsr = false">
                Zapri
              </v-btn>
              <v-btn elevation="4" type="submit" color="teal white--text"
                >Dodaj uporabnika</v-btn
              >
            </v-card-actions>
            <v-snackbar
              v-if="error"
              :timeout="timeout"
              :top="true"
              :multi-line="mode === 'multi-line'"
              :vertical="mode === 'vertical'"
              v-model="error"
            >
              {{ text }}
              <v-btn class="pink--text" text @click.native="error = false"
                >Close</v-btn
              >
            </v-snackbar>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="warning" :timeout="timeout">
      {{ emWarning }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="warning = false">
          Zapri
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import router from "../router";
import {
  mdiDelete,
  mdiPlusThick,
  mdiMinusThick,
  mdiCreditCard,
  mdiBarcodeScan,
  mdiCheck,
  mdiPrinter,
  mdiCloseBox,
} from "@mdi/js";

export default {
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    axios
      .get("/api/userlist", {
        headers: { Authorization: "Bearer " + user.token },
      })
      .then(
        function(response) {
          console.log(response);
          this.users = response.data;
        }.bind(this),
        (error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            router.push("/login");
          }
        }
      );
  },
  data: () => ({
    icons: {
      mdiDelete,
      mdiPlusThick,
      mdiMinusThick,
      mdiCreditCard,
      mdiBarcodeScan,
      mdiCheck,
      mdiPrinter,
      mdiCloseBox,
    },
    dialog: false,
    users: [],
    search: "",
    headers: [
      { text: "Uporabnik", value: "username" },
      { text: "Kreiran", value: "createdAt" },
      { text: "Posodobljen", value: "updatedAt" },
      { text: "Status", value: "active" },
      { text: "Popravi", value: "action" },
    ],
    reqRules: [
      (v) => !!v || "Vnos je obvezen",
      (v) => (v && v.length >= 5) || "Minimalna dolžina gesla je 6 znakov",
    ],
    itemSelected: "",
    newPass: "",
    confPass: "",
    error: "",
    warning: false,
    emWarning: "",
    timeout: 2000,
    addUsr: false,
    userName: "",
    name1: "",
    name2: "",
    authorization: "",
  }),

  methods: {
    changePass(item) {
      console.log(item);
      this.dialog = true;
      this.itemSelected = item;
    },
    passReset(e) {
      console.log(e);
      this.dialog = false;
      // this.submitted = true;
      const { newPass, confPass } = this;
      //   const { dispatch } = this.$store;
      if (newPass != confPass) {
        this.warning = true;
        this.emWarning = "Novo geslo se ne ujema";
        return;
      }
      if (newPass.length < 6) {
        this.warning = true;
        this.emWarning = "Novo geslo prekratko";
        return;
      }
      let user = JSON.parse(localStorage.getItem("user"));

      axios
        .post(
          "/api/change_password_adm",
          {
            pass: newPass,
            user: this.itemSelected.username,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            console.log("pride spremembe");
            this.warning = true;
            this.emWarning = response.data.msgTxt;
          },
          (error) => {
            this.warning = true;
            this.emWarning = error.response.data.errTxt;
          }
        );
    },
    addUser(e) {
      console.log(e);
      this.addUsr = false;

      //  this.userName =  this.name1 = this.name2 = this.authorization = this.newPass = this.confPass = "";
      // this.submitted = true;
      const { userName, name1, name2, authorization, newPass, confPass } = this;
      //   const { dispatch } = this.$store;
      if (newPass != confPass) {
        this.warning = true;
        this.emWarning = "Gesli se ne ujemata";
        return;
      }
      if (newPass.length < 6) {
        this.warning = true;
        this.emWarning = "Novo geslo prekratko";
        return;
      }
      let user = JSON.parse(localStorage.getItem("user"));

      axios
        .post(
          "/api/create_user",
          {
            pass: newPass,
            uname: userName,
            fName: name1,
            lName: name2,
            auth: authorization,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            console.log("pride spremembe");
            this.warning = true;
            this.emWarning = response.data.msgTxt;
            axios
              .get("/api/userlist", {
                headers: { Authorization: "Bearer " + user.token },
              })
              .then(
                function(response) {
                  console.log(response);
                  this.users = response.data;
                  this.userName = this.name1 = this.name2 = this.authorization = this.newPass = this.confPass =
                    "";
                }.bind(this),
                (error) => {
                  if (error.response.status === 401) {
                    localStorage.removeItem("user");
                    router.push("/login");
                  }
                }
              );
          },
          (error) => {
            this.warning = true;
            this.emWarning = error.response.data.errTxt;
            this.userName = this.name1 = this.name2 = this.authorization = this.newPass = this.confPass =
              "";
          }
        );
    },
    async blockUser(item) {
      console.log(item);
      let user = JSON.parse(localStorage.getItem("user"));
      let status_title;
      // let status;
      if (item.active) {
        status_title = "Blokiraj uporabnika";
        // status = true;
      } else {
        status_title = "Aktiviraj uporabnik";
        // status = false;
      }
      const res = await this.$confirm(status_title, {
        title: status_title,
        buttonTrueText: "Potrdi",
        buttonFalseText: "Zavrzi",
        color: "green",
      });
      if (res) {
        axios
          .post(
            "/api/block_user",
            {
              uname: item.username,
              status: item.active,
            },
            { headers: { Authorization: "Bearer " + user.token } }
          )
          .then(
            (response) => {
              console.log(response);
              let user = JSON.parse(localStorage.getItem("user"));
              axios
                .get("/api/userlist", {
                  headers: { Authorization: "Bearer " + user.token },
                })
                .then(
                  function(response) {
                    console.log(response);
                    this.users = response.data;
                  }.bind(this),
                  (error) => {
                    if (error.response.status === 401) {
                      localStorage.removeItem("user");
                      router.push("/login");
                    }
                  }
                );
            },
            (error) => {
              console.log(error);
            }
          );
      }
    },
  },
  computed: {},
  filters: {
    // you can call this whatever you want to

    formatQuantity(value) {
      value = value.toString();
      return value.replace(".", ",");
    },
  },
};
</script>
