<template>
  <v-container fill-height justify-center align-center>
    <v-flex xs12 sm6 md4 lg4>
      <v-card class="mt-0 pt-0" elevation="2">
        <v-card-title class="teal">
          <h5 style="color:white">Darilna kartica</h5>
        </v-card-title>
        <v-card-text class="mt-10">
          <form @submit.prevent="passReset">
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Trenutno geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="password"
                  label="Geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Novo geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="newPass"
                  label="Novo geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Ponovi geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="confPass"
                  label="Ponovi geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn elevation="4" type="submit" color="teal white--text">Ponastavi geslo</v-btn>
            </v-card-actions>
            <!-- <v-layout justify-space-between>
              <v-btn elevation="4" type="submit" color="primary">Vpis</v-btn>
              <v-btn elevation="4" color="primary" @click="passReset">Ponastavi geslo</v-btn>
            </v-layout> -->
            <v-snackbar
              v-if="error"
              :timeout="timeout"
              :top="true"
              :multi-line="mode === 'multi-line'"
              :vertical="mode === 'vertical'"
              v-model="error"
            >
              {{ text }}
              <v-btn class="pink--text" text @click.native="error = false"
                >Close</v-btn
              >
            </v-snackbar>
          </form>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-snackbar v-model="warning" :timeout="timeout">
      {{ emWarning }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="warning = false">
          Zapri
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      password: "",
      newPass: "",
      confPass: "",
      submitted: false,
      error: "",
      warning: false,
      emWarning: "",
      timeout: 2000,

      reqRules: [
        (v) => !!v || "Vnos je obvezen",
        (v) => (v && v.length >= 5) || "Minimalna dolžina gesla je 6 znakov",
      ],
    };
  },
  methods: {
    passReset(e) {
      console.log(e);
      this.submitted = true;
      const { newPass, password, confPass } = this;
      //   const { dispatch } = this.$store;
      if (newPass != confPass) {
        this.warning = true;
        this.emWarning = "Novo geslo se ne ujema";
        return;
      }
      if (newPass.length < 5) {
        this.warning = true;
        this.emWarning = "Novo geslo prekratko";
        return;
      }
      let user = JSON.parse(localStorage.getItem("user"));

      axios
        .post(
          "/api/change_password",
          {
            oldPass: password,
            newPass: newPass,
          },
          { headers: { Authorization: "Bearer " + user.token } }
        )
        .then(
          (response) => {
            this.warning = true;
            this.emWarning = response.data.msgTxt;
          },
          (error) => {
            this.warning = true;
            this.emWarning = error.response.data.errTxt;
            //  this.newPass = this.password = this.confPass = null;
          }
        );
    },
    // passReset(){

    // }
  },
};
</script>
