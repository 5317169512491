<!--<template>
    <div>
        <div class="alert alert-info">
            Username: test<br />
            Password: test
        </div>
        <h2>Login</h2>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary" :disabled="loggingIn">Login</button>
                <img v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </div>
        </form>
    </div>
</template>-->
<template>
  <v-container fill-height justify-center align-center>
    <v-flex xs12 sm6 md4 lg4>
      <v-card class="mt-0 pt-0" elevation="2">
        <!-- <v-card-title class="blue darken-1"> -->
          <v-card-title class="teal">
          <h4 style="color:white">Darilna kartica</h4>
        </v-card-title>
        <v-card-text class="mt-10">
          <form @submit.prevent="login">
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Uporabnik</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="username"
                  v-model="username"
                  label="Uporabnik"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Geslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="password"
                  label="Geslo"
                  value="Input text"
                  :rules="reqRules"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn elevation="4" type="submit" color="teal white--text">Vpis</v-btn>
            </v-card-actions>
            <!-- <v-layout justify-space-between>
              <v-btn elevation="4" type="submit" color="primary">Vpis</v-btn>
              <v-btn elevation="4" color="primary" @click="passReset">Ponastavi geslo</v-btn>
            </v-layout> -->
          <div >{{ stev }}</div>
        
            <v-snackbar v-model="warning" :timeout="timeout">
              {{ emWarning }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="warning = false"
                >
                  Zapri
                </v-btn>
              </template>
            </v-snackbar>
          </form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      warning: false,
      emWarning: "",
      timeout: 2000,
      reqRules: [(v) => !!v || "Vnos je obvezen"],
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    stev(){
      return this.$store.state.alert.message;
    }
  },
  
  created() {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    login(e) {
      console.log(e);
      // let that = this;
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch("authentication/login", { username, password });
        // console.log(hja);
        //  if (this.$store.state.alert != null) {
        //   let err = await this.$store.state.alert;
        //   console.log(err.message);
        //   that.warning = true;
        //   console.log("pod");
        //   that.emWarning = err.message;
        //   // this.$store.state.alert = "";
        //  }
      }
    },
    passReset() {},
  },
};
</script>
