var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',[_c('download-excel',{staticClass:"btn btn-default text-right",attrs:{"fetch":_vm.getExport,"fields":_vm.json_fields,"worksheet":"Kartice","type":"xls","name":_vm.getName}},[_c('v-btn',{attrs:{"depressed":"","color":"teal white--text"}},[_vm._v(" Izvoz podatkov ")])],1),_c('v-btn',{directives:[{name:"blur",rawName:"v-blur"}],attrs:{"color":"teal white--text","fab":"","small":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Išči","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cards,"search":_vm.search,"footer-props":{ itemsPerPageText: 'Zapisov na strani' }},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.active == '1' ? 'green' : item.active == '0' ? 'red' : 'grey',"dark":""}},[_vm._v(" "+_vm._s(item.active == "1" ? "Aktivna" : item.active == "0" ? "Neaktivna" : "grey")+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatQuantity")(item.value))+" ")]}},{key:"item.blockedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatBlockDate")(item.blockedAt))+" ")]}},{key:"item.issuedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.issuedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"teal mr-2",attrs:{"elevation":"4","fab":"","small":""},on:{"click":function($event){return _vm.deRegisterCard(item)}}},[_c('v-icon',[_vm._v(" mdi-checkbox-marked ")])],1),_c('v-btn',{staticClass:"teal",attrs:{"elevation":"4","fab":"","small":""},on:{"click":function($event){return _vm.blockCard(item)}}},[_c('v-icon',[_vm._v(" mdi-block-helper ")])],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" od "+_vm._s(props.itemsLength)+" ")]}}])}),_c('v-btn',{attrs:{"color":"teal white--text","dark":"","fixed":"","fab":"","x-large":"","bottom":"","right":""},on:{"click":_vm.scan}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiBarcodeScan)+" ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Čakam vnos kode...")]),_c('v-progress-linear',{attrs:{"color":"green accent-4","indeterminate":"","rounded":"","height":"6"}})],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"searchFocus",attrs:{"label":"Vnesi BAR kodo*","required":"","solo":""},on:{"input":_vm.charCount},model:{value:(_vm.bCode),callback:function ($$v) {_vm.bCode=$$v},expression:"bCode"}})],1)],1)],1),_c('small',[_vm._v("*prosim preberite črtno kodo")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Zapri ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.regCard}},[_vm._v(" Potrdi ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.warning = false}}},'v-btn',attrs,false),[_vm._v(" Zapri ")])]}}]),model:{value:(_vm.warning),callback:function ($$v) {_vm.warning=$$v},expression:"warning"}},[_vm._v(" "+_vm._s(_vm.emWarning)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }