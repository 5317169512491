import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    // theme: { dark: true },
    dark: false,
    
    // colors: {
    //   primary: "teal white--text"
    // }
});
