import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    scan: false
  },
  mutations: {
    updateLogged(state, logged){
      state.loggedIn = logged;
    }
  },
  actions: {
  },
  setScanAction (newValue) {
    if (this.debug) console.log('setMessageAction triggered with', newValue)
    this.scan = newValue
  },
  clearScanAction () {
    if (this.debug) console.log('clearMessageAction triggered')
    this.scan = false
  },
  modules: {
    alert,
    authentication,
    users
  }
})
