import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
import JsonExcel from "vue-json-excel";
import VuetifyConfirm from 'vuetify-confirm'

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.component("v-text-field", VTextField);
Vue.component("downloadExcel", JsonExcel);
Vue.directive('blur', {
  inserted: function (el) {
    el.onfocus = (ev) => ev.target.blur()
  }
});
export const EventBus = new Vue()
Vue.use(VCurrencyField, {
  locale: "sl",
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
Vue.use(VuetifyConfirm, {
  vuetify
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
